<template>
  <div id="app">
    <router-view v-show=" isRefresh"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isRefresh: true,
    };
  },
  components: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      (this.isRefresh = false),
        this.$nextTick(function () {
          this.isRefresh = true;
        }); // 刷新当前页面gggfff
    },
  },
 
};
</script>

<style>
#app {
  font-family: 'pingfangregular', PingFang TC, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media (max-width: 480px) {
  * {
    -webkit-tap-highlight-color: transparent;
  }
}
</style>
