// src/router/index.js就是路由模块

/* 1.导入Vue和VueRouter的包 */
import Vue from 'vue'
import VueRouter from 'vue-router'

/* 2.调用Vue.use()函数，把VueRouter安装为Vue的插件 */
Vue.use(VueRouter)

import before from '@/router/before'

const home = () => import('@/components/blackhome')
const login = () => import('@/components/login')
const timeorderMian = () => import('@/components/realTtimeorder/realTtimeorderMian')
const Winresultsmian = () => import('@/components/Winningresults/blackresultmian')
const Personalcenter = () => import('@/components/PersonalCenter/PersonalCenterMain')
/* 3.创建路由实例对象 */
const router = new VueRouter({
    routes: [
        {
            path: '/home',
            component: home
        },
        {
            path: '/login',
            component: login
        },
        {
            path: '/time-pool',
            component: timeorderMian
        },
        {
            path: '/Win-results',
            component: Winresultsmian
        },
        {
            path: '/Personal-center',
            component: Personalcenter
        },
        {
            path: '/m/liveOrder',
            component: () => import('@/view/mobile/liveOrder/index.vue')
        },
        {
            path: '/',
            redirect: '/home'
        }
    ],
    mode: 'history',
})

router.beforeEach(before)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

/* 4.向外共享router */
export default router
