<template>
  <div class="syj_popsuccess" v-show="isShow">
    <div class="syj_border-linear"></div>
    <div class="bgwhite cursorPointer">
      <div class="common-pop">
        <img
            @click="ispopClose"
            src="../../../assets/images/close.png"
            class="close"
        />
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  data() {
    return {
      message: "",
      isShow: false,
    };
  },
  mounted() {
    window.Toast = this.show;
  },
  methods: {
    show(msg, delay = false) {
      this.message = msg;
      this.isShow = true;
      if (delay) {
        setTimeout(() => {
          this.message = "";
          this.isShow = false;
        }, delay);
      }
    },
    ispopClose() {
      this.isShow = false;
    }
  },
};
</script>
<style lang="less">
.syj_popsuccess {
  .bgwhite {
    background: #ffff;
    margin: 5px;
  }
  
  .common-pop {
    padding: 10px;
    
    img {
      width: 15px;
    }
  }
  
  min-width: 400px;
  box-shadow: 1px 2px 13px 5px #b5b5b5;
  border-radius: 4px;
  overflow: hidden;
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
}

@media (max-width: 480px) {
  .syj_popsuccess {
    min-width: 200px;
    width: 90%;
  }
}
</style>
