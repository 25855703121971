let development_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJndWVzcy1hZG1pbi5tYWl5dWFuLm9ubGluZSIsImF1ZCI6Imd1ZXNzLWFkbWluLm1haXl1YW4ub25saW5lIiwiaWF0IjoxNzMxMDI4OTg4LCJuYmYiOjE3MzEwMjg5ODgsImV4cCI6MTc2MjU2NDk4OCwianRpIjp7ImlkIjo1MTQsInR5cGUiOiJhcGkifX0.UR9AaZLpnvJLcGnW-dVeVXAfWmmivtGjqTENUKtzR5w';

export const setToken = (token) => {
	localStorage.setItem('token', token)
}


export const getToken = () => {
	// const token = localStorage.getItem('token')
	const token = process.env.NODE_ENV == 'development' ? development_token : localStorage.getItem('token')
	if (token) {
		return token
	} else {
		return false
	}
}
