import {getOS} from '@/units/common'
import {getToken} from "@/units/token";
const device = getOS()

export default (to, from, next) => {
    const token = getToken()
    if (token && to.path !== '/m/liveOrder' && device === 'phone') {
        return next('/m/liveOrder')
    }
    next()
}
