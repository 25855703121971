import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import './assets/style/mian-css.less'
import './assets/fonts/font.css'
// import api from './api/index.js' //接口路径封装总部分
import * as echarts from 'echarts';// 导入自定义插件 Toast
import toast from "@/components/common/toast/index.js"
import VueSimpleAlert from "vue-simple-alert";
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// 引入通信总件，用于非父子组件间的传值
import Bus from '@/units/bus'
// 并将其挂载到vue的原型上
Vue.prototype.$bus = Bus

Vue.use(VueSimpleAlert);
Vue.use(toast);
// Vue.use(ElementUI);
Vue.config.productionTip = false

// 全局方法挂载
// Vue.prototype.echarts = echarts
// console.log('dddcccc')
Vue.prototype.$echarts = echarts
// Vue.prototype.$api = api //设置一个api
// console.log('ddd',Vue.prototype)
import  { vueBaberrage } from 'vue-baberrage'
Vue.use(vueBaberrage)

console.table(
  '%c欢迎访问竞猜系统',
  'color: red; background: yellow; font-size: 24px;'
)
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
// 路由跳转1
